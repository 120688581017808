import { notification } from 'antd';
import type { AxiosError } from 'axios';
import axios from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { Org } from '../../../enum';
import { AdvocatePage, APIStatus } from '../../../enum';
import { environment } from '../../../environment/environment';
import type { ErrorMessage } from '../../../models/error';
import type { AsyncThunkConfig } from '../../../models/slice';
import { SortedInfo } from '../../../models/table';
import { RaygunErrorHandlerService } from '../../../service/raygun.service';
import type { AdminManager } from '../admin-managers/adminManagerSlice';
import type { AdvocateType } from './advocate-upsert.helper';

const { logError } = RaygunErrorHandlerService();

export class AdvocateUpsert {
  id: string | null = null;
  address: string | undefined = undefined;
  city: string | undefined = undefined;
  country: string = 'Canada';
  email: string | undefined = undefined;
  first_name: string | undefined = undefined;
  last_name: string | undefined = undefined;
  nickname: string | null = null;
  province: string | undefined = undefined;
  postal_code: string | undefined = undefined;
  img_url: string | null = null;
  sin: string | undefined = undefined;
  label_ids: string[] = [];
  payment_system_id: string | undefined = undefined;
  advocate_type: AdvocateType | undefined = undefined;
  branch: string | undefined = undefined;
  bank_code: string | undefined = undefined;
  account_number: string | undefined = undefined;
  mobile_phone: string | null = null;
  dart: boolean | undefined = false;
  org_code: Org;
  org_use_dart: boolean | undefined = false;
  manager_ids: string[] = [];

  temp_password?: string = undefined;
  account_status?: string = undefined;
  tag_id?: string = undefined;

  // Service provider
  service_provider_organization_id?: string = undefined;
  service_provider_title?: string = undefined;
  service_title?: string = undefined;
  service_description?: string = undefined;

  // Buddy's Quest
  cohort_ids: string[] = [];
}

export type Label = {
  alias: string;
  id: string;
  name: string;
  order: number;
  org_code: Org;
};

export type Language = {
  language_code: string;
  name: string;
  english_name: string;
  order: number;
};

export type Cohort = {
  id: string;
  name: string;
  org_code: Org;
};

export type showAdvocate = {
  advocate_id: string;
  show: boolean;
};

type AdvocateUpsertSlideType = {
  advocate: AdvocateUpsert;
  labels: Label[];
  managers: AdminManager[];
  cohorts: Cohort[];
  advocateUpsertFormDisabled: boolean;
  upsertAdvocateApiStatus: APIStatus;
  confirmEmail: boolean;
  successModal: boolean;
  advocateId: string | undefined;
  individualAdvocateApiStatus: APIStatus;
  languagesApiStatus: APIStatus;
  labelsApiStatus: APIStatus;
  randomAdvocateStatus: boolean;
};

const initialState: AdvocateUpsertSlideType = {
  advocate: new AdvocateUpsert(),
  labels: [],
  managers: [],
  cohorts: [],
  individualAdvocateApiStatus: APIStatus.IDLE,
  languagesApiStatus: APIStatus.IDLE,
  labelsApiStatus: APIStatus.IDLE,
  upsertAdvocateApiStatus: APIStatus.IDLE,
  advocateUpsertFormDisabled: true,
  confirmEmail: false,
  successModal: false,
  advocateId: undefined,
  randomAdvocateStatus: !environment.environment_deploy.includes('prod'),
};

export const fetchIndividualAdvocate = createAsyncThunk<
  AdvocateUpsert,
  { advocate_id: string; type: AdvocatePage },
  AsyncThunkConfig
>('advocateUpsert/fetchIndividualAdvocate', async ({ advocate_id, type }, thunkAPI) => {
  try {
    const url = type === AdvocatePage.PeerAdvocate ? 'v0_leps_settings' : 'v0_service_provider_settings';
    const response = (await axios.post(url, { advocate_id })) as AdvocateUpsert;
    return response ?? new AdvocateUpsert();
  } catch (e) {
    logError(e, ['advocateUpsertSlice', 'fetchIndividualAdvocate', type]);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

type AdvocateUpsertResponse = {
  advocate_id: string;
  is_update: boolean;
};

export const saveUpsertAdvocate = createAsyncThunk<
  AdvocateUpsertResponse,
  { upsertAdvocate: AdvocateUpsert; type: AdvocatePage },
  AsyncThunkConfig
>('advocateUpsert/saveUpsertAdvocate', async ({ upsertAdvocate, type }, thunkAPI) => {
  try {
    const url =
      type === AdvocatePage.PeerAdvocate
        ? upsertAdvocate.id
          ? '/v0_leps_update'
          : '/v0_leps_insert'
        : upsertAdvocate.id
          ? '/v0_service_provider_update'
          : '/v0_service_provider_insert';
    const response = (await axios.post(url, upsertAdvocate)) as AdvocateUpsertResponse;
    if (response.advocate_id) {
      thunkAPI.dispatch(setAdvocateId(response.advocate_id));
      if (response.is_update) {
        notification.success({
          message: 'Update successful',
          description: 'Edited information has been saved.',
          style: { border: '1px solid #52c41a' },
        });
        thunkAPI.dispatch(disableAdvocateSettingsForm(true));
      } else {
        thunkAPI.dispatch(showSuccessModal(false));
      }
    }
    return response;
  } catch (e) {
    logError(e, ['advocateUpsertSlice', 'saveUpsertAdvocate']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const logSinAccess = createAsyncThunk<string, showAdvocate, AsyncThunkConfig>(
  'advocateUpsert/logSinAccess',
  async (payload: showAdvocate, thunkAPI) => {
    try {
      const response = (await axios.post('v0_log_sin_access', payload)) as string;
      return response;
    } catch (e) {
      logError(e, ['advocateUpsertSlice', 'logSinAccess']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAllLabels = createAsyncThunk<Label[], undefined, AsyncThunkConfig>(
  'advocateUpsert/fetchAllLabels',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.get('v0_get_labels')) as Label[];
      return response ?? [];
    } catch (e) {
      logError(e, ['advocateUpsertSlice', 'fetchAllLabels']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAllManagers = createAsyncThunk<AdminManager[], AdvocatePage, AsyncThunkConfig>(
  'advocateUpsert/fetchAllManagers',
  async (type, thunkAPI) => {
    try {
      const endpointUrl = type === AdvocatePage.PeerAdvocate ? 'v0_get_managers' : 'v0_get_service_provider_managers';
      const params = { sortedInfo: new SortedInfo<AdminManager>('full_name', 'ascend') };
      const response = (await axios.post(endpointUrl, params)) as AdminManager[];
      return response ?? [];
    } catch (e) {
      logError(e, ['advocateUpsertSlice', 'fetchAllManagers', type]);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchAllCohorts = createAsyncThunk<Cohort[], undefined, AsyncThunkConfig>(
  'advocateUpsert/fetchAllCohorts',
  async (_, thunkAPI) => {
    try {
      const response = (await axios.post('v0_get_cohorts')) as Cohort[];
      return response ?? [];
    } catch (e) {
      logError(e, ['advocateUpsertSlice', 'fetchAllCohorts']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const advocateUpsertSlice = createSlice({
  name: 'advocateUpsert',
  initialState,
  reducers: {
    setAdvocate: (state, action: PayloadAction<AdvocateUpsert>) => {
      state.advocate = action.payload;
    },
    disableAdvocateSettingsForm: (state, action: PayloadAction<boolean>) => {
      state.advocateUpsertFormDisabled = action.payload;
    },
    showConfirmEmailModal: (state, action: PayloadAction<boolean>) => {
      state.confirmEmail = action.payload;
    },
    showSuccessModal: (state, action: PayloadAction<boolean>) => {
      state.successModal = action.payload;
    },
    setAdvocateId: (state, action: PayloadAction<string>) => {
      state.advocateId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveUpsertAdvocate.pending, (state, _action) => {
        state.upsertAdvocateApiStatus = APIStatus.PENDING;
      })
      .addCase(saveUpsertAdvocate.fulfilled, (state, _action) => {
        state.upsertAdvocateApiStatus = APIStatus.FULFILLED;
      })
      .addCase(saveUpsertAdvocate.rejected, (state, _action) => {
        state.upsertAdvocateApiStatus = APIStatus.ERROR;
      })
      .addCase(logSinAccess.pending, (state, _action) => {
        state.upsertAdvocateApiStatus = APIStatus.PENDING;
      })
      .addCase(logSinAccess.fulfilled, (state, _action) => {
        state.upsertAdvocateApiStatus = APIStatus.FULFILLED;
      })
      .addCase(logSinAccess.rejected, (state, _action) => {
        state.upsertAdvocateApiStatus = APIStatus.ERROR;
      })
      .addCase(fetchIndividualAdvocate.pending, (state, _action) => {
        state.individualAdvocateApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchIndividualAdvocate.fulfilled, (state, action) => {
        state.advocate = action.payload;
        state.individualAdvocateApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAllLabels.pending, (state, _action) => {
        state.labelsApiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAllLabels.fulfilled, (state, action) => {
        state.labels = action.payload;
        state.labelsApiStatus = APIStatus.ERROR;
      })
      .addCase(fetchAllLabels.rejected, (state, _action) => {
        state.labelsApiStatus = APIStatus.ERROR;
        state.labelsApiStatus = APIStatus.FULFILLED;
      })
      .addCase(fetchAllManagers.fulfilled, (state, action) => {
        state.managers = action.payload;
      })
      .addCase(fetchAllCohorts.fulfilled, (state, action) => {
        state.cohorts = action.payload;
      });
  },
});

export const { disableAdvocateSettingsForm, setAdvocate, showConfirmEmailModal, showSuccessModal, setAdvocateId } =
  advocateUpsertSlice.actions;
